<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <lparte-trabajo-form
          v-if="idarticulo"
          :id="null"
          :idparte-trabajo="parseInt(routeParams.idparte_trabajo)"
          :desde-o-t="desdeOT"
          :idarticulo="idarticulo"
          :idsubsis="idsubsis"
          :unidades="unidades"
          :facturar="facturar"
          :idtmotivo-nofacturable="idtmotivoNofacturable"
          :has-perm-ver-tarifas="hasViewPerm(permissions.articulos.verTarifas)"
          :has-perm-modificar-material-facturable="hasViewPerm(permissions.partes.modificarMaterialFacturable)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import Data from './LparteTrabajoAddData'
import LparteTrabajoForm from '../components/LparteTrabajoForm'
import { encodeExtraQuery } from '@/utils/router'

export default {
  components: {
    LparteTrabajoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      articulo: {},
      lordenTrabajo: {},
    }
  },
  computed: {
    desdeOT () {
      return !!this.routeParams.idlorden_trabajo
    },
    idarticulo () {
      if (this.desdeOT) {
        return this.lordenTrabajo.lorden_trabajo?.idarticulo
      } else {
        return this.routeParams.idarticulo
      }
    },
    idsubsis () {
      if (this.desdeOT) {
        return this.lordenTrabajo.lorden_trabajo?.idsubsis
      } else {
        return this.routeQuery.idsubsis
      }
    },
    unidades () {
      return this.lordenTrabajo.lorden_trabajo?.unidades_pend_instalar
    },
    facturar () {
      return this.lordenTrabajo.lorden_trabajo?.facturar
    },
    idtmotivoNofacturable () {
      return this.lordenTrabajo.lorden_trabajo?.idtmotivo_nofacturable
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      let idarticulo
      if (this.desdeOT) {
        this.lordenTrabajo = await this.$offline.lordenTrabajo.row(this.routeParams.idlorden_trabajo)
        idarticulo = this.lordenTrabajo.lorden_trabajo.idarticulo
      } else {
        idarticulo = this.routeParams.idarticulo
      }
      this.articulo = await Data.rowArticulo(this, idarticulo)
      this.title = `Añadir ${this.articulo.articulo.descripcion}`
    },
    async submitForm () {
      const materialInstalarInsertado = []
      if (this.desdeOT) {
        materialInstalarInsertado.push(
          await Data.insertLparte(
            this,
            this.routeParams.idparte_trabajo,
            this.routeParams.idlorden_trabajo,
            this.lordenTrabajo.lorden_trabajo.idsubsis,
            this.formData
          )
        )
        await this.$dirty.modified(this.$dirty.ENTITIES.local.lordenTrabajo, this.routeParams.idlorden_trabajo)
      } else {
        let idsubsis
        // no existe el subsistema
        if (this.formData.idsubsis_idtsubsis.tipo === 'tsubsis') {
          const parteTrabajo = await this.$offline.parteTrabajo.row(this.routeParams.idparte_trabajo)
          const idordenTrabajo = parteTrabajo.parte_trabajo.idorden_trabajo
          const ordenTrabajo = await this.$offline.ordenTrabajo.row(idordenTrabajo)
          idsubsis = await Data.insertSubsis(
            this,
            ordenTrabajo.orden_trabajo.idsistema,
            this.formData.idsubsis_idtsubsis.idtsubsis,
            this.formData.subsis_descripcion,
            this.formData.subsis_codigo,
            idordenTrabajo
          )
        } else {
          idsubsis = this.formData.idsubsis_idtsubsis.id
        }
        if (this.formData.unidades > 1 && this.formData.desglosar) {
          let count = 0
          while (count < this.formData.unidades) {
            const lparteTrabajoIns = await Data.insertLparteLordenyDetalle(
              this,
              this.routeParams.idparte_trabajo,
              this.articulo.articulo.codigo,
              1,
              idsubsis,
              this.formData
            )
            materialInstalarInsertado.push(lparteTrabajoIns.idlparteTrabajo)
            count++
          }
        } else {
          const lparteTrabajoIns = await Data.insertLparteLordenyDetalle(
            this,
            this.routeParams.idparte_trabajo,
            this.articulo.articulo.codigo,
            this.formData.unidades,
            idsubsis,
            this.formData
          )
          materialInstalarInsertado.push(lparteTrabajoIns.idlparteTrabajo)
        }
      }
      await this.$dirty.modified(this.$dirty.ENTITIES.local.lparteTrabajoSubsis, this.formData.idsubsis)
      this.$appRouter.replace({
        name: 'offline__lparte-trabajo-edit',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idlparte_trabajo: materialInstalarInsertado[0],
        },
        query: {
          extra: encodeExtraQuery({ ids: materialInstalarInsertado }),
        },
      })
    },
  }
}
</script>
