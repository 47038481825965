import { v4 as uuidv4 } from 'uuid'

export default {
  async rowArticulo (Vue, idarticulo) {
    const tables = Vue.$offline.db.tables
    const articulo = await Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.articulo.idarticulo.eq(idarticulo)
        )
      )
      .exec()
    return articulo[0]
  },
  async insertSubsis (Vue, idsistema, idtsubsis, descripcion, codigo, idordenTrabajo) {
    let idsubsis = uuidv4()
    await Vue.$offline.subsis.insertSync({
      idsubsis,
      idsistema,
      idtsubsis,
      descripcion,
      codigo,
      estado: 1,
    })
    await Vue.$offline.ordenTrabajoSubsis.insertOrdenTrabajoSubsis(idordenTrabajo, idsubsis)
    return idsubsis
  },
  async insertLparteLordenyDetalle (Vue, idparteTrabajo, codigoArticulo, unidades, idsubsis, formData) {
    return await Vue.$offline.lparteTrabajo.insertLparteLordenYDetalleSync(
      idparteTrabajo,
      codigoArticulo,
      unidades,
      idsubsis,
      formData.tinclusion,
      formData.facturar,
      formData.idtmotivo_nofacturable,
      formData.descripcion
    )
  },
  async insertLparte (
    Vue, idparteTrabajo, idlordenTrabajo, idsubsis, formData
  ) {
    const maxOrden = await Vue.$offline.lparteTrabajo.rowNordenMaterialInstalar(idparteTrabajo)
    const lordenTrabajo = await Vue.$offline.lordenTrabajo.row(idlordenTrabajo)
    const idlparteTrabajo = uuidv4()
    await Vue.$offline.lparteTrabajo.insertSync({
      idlparte_trabajo: idlparteTrabajo,
      idparte_trabajo: idparteTrabajo,
      idlorden_trabajo: idlordenTrabajo,
      unidades: formData.unidades,
      unidades_orig: formData.unidades,
      idsubsis,
      facturar: formData.facturar,
      idtmotivo_nofacturable: formData.idtmotivo_nofacturable,
      orden: maxOrden.orden + 1,
      descripcion: lordenTrabajo.lorden_trabajo.descripcion,
      idarticulo: lordenTrabajo.lorden_trabajo.idarticulo,
      codigo: lordenTrabajo.lorden_trabajo.codigo,
      numzona: lordenTrabajo.lorden_trabajo.numzona,
      evento: lordenTrabajo.lorden_trabajo.evento,
      ubicacion: lordenTrabajo.lorden_trabajo.ubicacion,
      nserie: lordenTrabajo.lorden_trabajo.nserie,
      observaciones: lordenTrabajo.lorden_trabajo.observacion,
      kilometraje: lordenTrabajo.lorden_trabajo.kilometraje,
      nelemento: lordenTrabajo.lorden_trabajo.nelemento,
      idfabricante: lordenTrabajo.lorden_trabajo.idfabricante,
      ffabricacion: lordenTrabajo.lorden_trabajo.ffabricacion,
      funcion: lordenTrabajo.lorden_trabajo.funcion,
      idagente_propelente: lordenTrabajo.lorden_trabajo.idagente_propelente,
      idagente_extintor: lordenTrabajo.lorden_trabajo.idagente_extintor,
      volumen: lordenTrabajo.lorden_trabajo.volumen,
      eficacia: lordenTrabajo.lorden_trabajo.eficacia,
      presion: lordenTrabajo.lorden_trabajo.presion,
      peso_total: lordenTrabajo.lorden_trabajo.peso_total,
      peso_agente_extintor: lordenTrabajo.lorden_trabajo.peso_agente_extintor,
      netiqueta: lordenTrabajo.lorden_trabajo.netiqueta,
      particion: lordenTrabajo.lorden_trabajo.particion,
      estado: 1,
    })
    return idlparteTrabajo
  }
}
